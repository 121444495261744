import axios from 'axios';
import React, { useState } from 'react';

const Action = () => {
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    
    const handleSubmit = async(e) =>{
        e.preventDefault();
        try{
            const res = await axios.post(`http://localhost:5000/api/register`,{
                email,
                phone
            });
            alert("Thank you")
        }catch(error){
            console.log(error)
        }
    }

    return (
        <div className='body'>
            <div className='wrappers'>
                <div className="action-container">

                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <img src='' className='img-fluid'></img>
                                <h2 className='action-heading'>Your Name , where shall we  <br>
                                </br>
                                
                                <strong>Send Your Certificate</strong> </h2>
                            </div>

                            <div className='col-lg-6'>
                                <form onSubmit={handleSubmit}>
                                   <div className='mb-3'>
                                   <label className='form-lable'>Whatsapp Number</label>
                                   <input className='form-control' placeholder='Enter your phone number' 
                                   value={phone}
                                   onChange={(e)=>setPhone(e.target.value)}/>
                                   </div>
                                   <div className='mb-3'>
                                   <label className='form-lable'>E-Mail</label>
                                   <input className='form-control' placeholder='enter your email'
                                   value={email}
                                   onChange={(e)=>setEmail(e.target.value)}/>
                                   </div>
                                   <div className='mb-3'>
                                   <input type='submit' className='btn btn-primary' value="Submit"/>
                                   </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Action;
