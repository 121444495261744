import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Certificate = () => {
  const [faqs, setFaqs] = useState([]);
  const [certificateUrl, setCertificateUrl] = useState(null);

  const username = localStorage.getItem('username');
  const userId = localStorage.getItem('userId');
  const score = localStorage.getItem('score'); // Ensure score is correctly set and retrieved
  const phoneNo = localStorage.getItem('phoneNo');
  const mail = localStorage.getItem('mail');

  console.log("Score is ", score)

  useEffect(() => {
    if (userId) {
      // Fetch the FAQ data from the API
      axios.get(`${process.env.REACT_APP_API_URL}/api/faq`)
        .then(response => {
          setFaqs(response.data);
        })
        .catch(error => {
          console.error('Error fetching FAQs:', error);
        });

      // Check if the user already has a certificate
      axios.get(`${process.env.REACT_APP_API_URL}/api/certificate/${userId}`)
        .then(response => {
          if (response.data.certificateUrl) {
            setCertificateUrl(response.data.certificateUrl);
          }
        })
        .catch(error => {
          console.error('Error fetching certificate:', error);
        });
    }
  }, [userId]);

  const handlePayment = async () => {
    if (!score) {
      alert('Score is required to proceed with payment.');
      return;
    }
  
    try {
      // Create a new order on the server
      const { data: order } = await axios.post(`${process.env.REACT_APP_API_URL}/api/create-order`, {
        amount: 199, // Amount in INR
        currency: 'INR',
        userId,
      });
  
      // Configure Razorpay
      const options = {
        key: 'rzp_live_xvyUPSkCFp1ytU', // Your Razorpay key ID
        amount: order.amount,
        currency: order.currency,
        name: 'Arrival Solutions',
        description: 'Certificate Payment',
        order_id: order.id,
        handler: async function (response) {
          try {
            // Verify the payment on the server
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/api/verify-payment`, {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              userId,
            });
  
            // After payment is verified, generate the certificate
            const certificateResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/generate-certificate`, {
              userId,
              score,
            });
  
            setCertificateUrl(certificateResponse.data.certificateUrl);
  
            // Auto-download the certificate
            const downloadLink = document.createElement('a');
            downloadLink.href = `${process.env.REACT_APP_API_URL}${certificateResponse.data.certificateUrl}`;
            downloadLink.download = 'certificate.pdf';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
  
            alert('Payment Successful! Your certificate will be downloaded.');
          } catch (error) {
            console.error('Payment verification or certificate generation failed:', error);
            alert('There was an error processing your payment. Please try again.');
          }
        },
        prefill: {
          name: username,
          email: mail,
          contact: phoneNo,
        },
        theme: {
          color: '#3399cc',
        },
      };
  
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error('Error initiating payment:', error);
      alert('There was an error initiating the payment. Please try again.');
    }
  };
  
  

  return (
    <div className='container col-lg-6 mx-auto my-5'>
      <div className='topbar'>
        <h1>Congrats {username}, <br /><span>You've Passed</span></h1>
      </div>

      <div className='certificate'>
        <div className='container col-lg-10 mx-auto py-2'>
          <h2>
            Unlock Instant Certificate to View Score and Prove You're in the Top <span>10% of UI & UX Candidates</span>
          </h2>

          <img src='./../img/sample.png' alt='certificate' className='img-fluid' />

          <p className='lead my-3'><strong>8/10 CVs</strong> with this certificate got hired</p>

          {/* Show payment button or download certificate link */}
          {!certificateUrl ? (
           <>
            <button className='btn btn-certificate my-3' onClick={handlePayment}>
              Get Instant Certificate
            </button>
            <p className='lead my-3'>For Just &#x20B9; 199 <del className='text-danger'>&#x20B9;  599</del> </p>
           </>
          ) : (
            <a href={certificateUrl} className='btn btn-certificate my-3' download>
              Download Certificate
            </a>
          )}

          <h5 className='text-center'>Explore Additional Features</h5>
          <p className='text-center'><i className="bi bi-chevron-down"></i></p>
        </div>
      </div>

      {/* FAQ Section */}
      <div className='faq-card'>
        <div className="accordion accordion-flush" id="accordionFlushExample">
          {faqs.map((faq, index) => (
            <div className="accordion-item" key={faq._id}>
              <h2 className="accordion-header" id={`flush-heading${index}`}>
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls={`flush-collapse${index}`}>
                  {faq.question}
                </button>
              </h2>
              <div id={`flush-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`flush-heading${index}`} data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  {faq.answer}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Certificate;
