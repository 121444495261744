import React from 'react';


const SalaryReport = () => {
  return (
    <div className="container-fluid text-center vh-100 d-flex flex-column align-items-center justify-content-center">
      {/* Logo */}
      <div className="row mb-5">
        <div className="col">
          <h1 className="text-white">Logo</h1>
        </div>
      </div>

      {/* Text Section */}
      <div className="row mb-4">
        <div className="col">
          <h2 className="text-white">
            Our Learners Report <span className="text-highlight">43% Higher Salaries</span> Than Average
          </h2>
        </div>
      </div>

      {/* Circular Images Section */}
      <div className="row mb-3 justify-content-center">
        <div className="col-10 col-md-8">
          <div className="d-flex flex-wrap justify-content-center align-items-center circle-container">
            {/* Circle 1 */}
            <div className="circle">
              <img src="person1.jpg" alt="Ayushi Singh" className="circle-img" />
              <div className="overlay">
                <p>₹8LPA</p>
                <p>Ayushi Singh</p>
              </div>
            </div>
            {/* Circle 2 */}
            <div className="circle">
              <img src="person2.jpg" alt="Saransh Das" className="circle-img" />
              <div className="overlay">
                <p>₹26LPA</p>
                <p>Saransh Das</p>
              </div>
            </div>
            {/* Circle 3 */}
            <div className="circle">
              <img src="person3.jpg" alt="Sumit Gupta" className="circle-img" />
              <div className="overlay">
                <p>₹24LPA</p>
                <p>Sumit Gupta</p>
              </div>
            </div>
            {/* Circle 4 */}
            <div className="circle">
              <img src="person4.jpg" alt="Nagurjun SN" className="circle-img" />
              <div className="overlay">
                <p>₹19LPA</p>
                <p>Nagurjun SN</p>
              </div>
            </div>
            {/* Circle 5 */}
            <div className="circle">
              <img src="person5.jpg" alt="Rashi Jain" className="circle-img" />
              <div className="overlay">
                <p>₹8LPA</p>
                <p>Rashi Jain</p>
              </div>
            </div>
            {/* Circle 6 */}
            <div className="circle">
              <img src="person6.jpg" alt="Aditya Shah" className="circle-img" />
              <div className="overlay">
                <p>₹8LPA</p>
                <p>Aditya Shah</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryReport;
