import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Quiz = () => {
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [progress, setProgress] = useState(0);
    const [score, setScore] = useState(0);
    const [quizCompleted, setQuizCompleted] = useState(false);
    const navigate = useNavigate();

    // Fetch quiz data from API
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/quizzes`)
            .then(response => {
                setQuestions(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the quiz data!", error);
            });
    }, []);

    // Handle option selection
    const handleOptionSelect = (option) => {
        setSelectedOption(option);

        // Check if the selected option is correct and update the score
        if (option === questions[currentQuestionIndex].answer) {
            setScore(prevScore => prevScore + 1);
        }

        // Move to the next question after a short delay
        setTimeout(() => {
            const nextQuestionIndex = currentQuestionIndex + 1;
            if (nextQuestionIndex < questions.length) {
                setCurrentQuestionIndex(nextQuestionIndex);
                setSelectedOption('');
                setProgress(((nextQuestionIndex + 1) / questions.length) * 100);
            } else {
                // Quiz completed
                setQuizCompleted(true);
                setProgress(100); // Show 100% progress on completion
            }
        }, 500);
    };

    console.log("My Score is : ", score);
    localStorage.setItem('score', score);
    
    if (questions.length === 0) {
        return <div>Loading...</div>;
    }

    const username = localStorage.getItem('username');
    console.log(username, "user name");

    if (quizCompleted) {
        return (
            <div className='body'>
                <div className='container wrappers mt-sm-5'>
                    <div className="quiz-container mt-sm-5 text-center">
                        <h2>Quiz Completed!</h2>
                     
                        {score >= 6 ? (
                          <>
                          <h3>Congratulations {username}, You Qualified!</h3>
                            <button
                                className="btn btn-success mt-4 w-100"
                                onClick={() => navigate('/certificate')}
                            >
                                Get Certificate
                            </button>
                          </>
                        ) : (
                           <>
                             <h3>Sorry {username}, You Did Not Qualify</h3>
                            <button
                                className="btn btn-danger mt-4 w-100"
                                onClick={() => window.location.reload()}
                            >
                                Retake Test
                            </button>
                           </>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    const currentQuestion = questions[currentQuestionIndex];

    return (
        <div className='body'>
            <div className='wrappers mt-sm-5'>
                <div className="quiz-container mt-sm-5">

                    {/* Progress bar */}
                    <div className="progress-bar-custom mt-sm-5 mb-3">
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{ width: `${progress}%`, backgroundColor: '#ffcc00' }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <span className="mt-4">{currentQuestionIndex + 1}/{questions.length}</span>
                    </div>

                    <p className='text-left mt-4 pt-2'>86% of people who completed this quiz got hired</p>

                    {/* Question */}
                    <h3 className="mb-4 text-left">{currentQuestion.question}</h3>

                    {/* Options */}
                    <ul className="options-list text-start">
                        {currentQuestion.options.map((option, index) => (
                            <li key={index} className={selectedOption === option ? 'selected' : ''}>
                                <input
                                    type="radio"
                                    id={`option${index}`}
                                    name="quiz"
                                    value={option}
                                    checked={selectedOption === option}
                                    onChange={() => handleOptionSelect(option)}
                                />
                                <label htmlFor={`option${index}`} className='ms-2'>{option}</label>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Quiz;
