import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Entry = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/register`, {
                name,
                email,
                phone
            });

            const username = res.data.name;
            const userId = res.data._id;
            const mail = res.data.email;
            const phoneNo = res.data.phone;

            localStorage.setItem('userId', userId);
            localStorage.setItem('username', username);
            localStorage.setItem('mail', mail);
            localStorage.setItem('phoneNo', phoneNo);

            setShowModal(true); // Show the modal after successful submission
        } catch (error) {
            console.log(error);
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
        navigate('/quiz'); // Navigate to quiz after closing the modal
    };

    return (
        <div className='body'>
            <div className='wrappers'>
                <div className="quiz-container">
                    <form onSubmit={handleSubmit}>
                        <div className='mb-3'>
                            <label>Full Name</label>
                            <input
                                className='form-control'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div className='mb-3'>
                            <label>Phone Number</label>
                            <input
                                className='form-control'
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                            />
                        </div>
                        <div className='mb-3'>
                            <label>Email Address</label>
                            <input
                                className='form-control'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className='mb-3'>
                            <button type="submit" className="btn btn-success btn-lg w-100">Submit</button>
                        </div>
                    </form>
                </div>
            </div>

            {/* Modal */}
            {showModal && (
                <div className="modal show d-block" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" style={{ color: '#ff5722' }}>your UI/UX Test Journey Started</h5> {/* Change text color */}
                                <button type="button" className="btn-close" onClick={handleModalClose}></button>
                            </div>
                            <div className="modal-body">
                                <p style={{ color: '#4caf50' }}>
                                    <ul>
                                        <li>Timing: The exam must be completed within the allocated time.</li>
                                        <li>Browser Tab Changes: Changing browser tabs will be monitored.</li>
                                    </ul>
                                </p> {/* Change text color */}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={handleModalClose}>Go to Quiz</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Entry;
