import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const CertificateGenerator = () => {
  const { userId } = useParams(); // Get userId from route parameters

  useEffect(() => {
    // Trigger the download when the component mounts
    axios.get(`${process.env.REACT_APP_API_URL}/api/certificate/${userId}`, {
      responseType: 'blob'
    })
    .then(response => {
      // Create a link element and trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${userId}_Certificate.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(error => {
      console.error('Error downloading certificate:', error);
    });
  }, [userId]);

  return (
    <div className='container col-lg-6 mx-auto my-5'>
      <h2>Downloading Your Certificate...</h2>
    </div>
  );
};

export default CertificateGenerator;
