import React from "react";
import { Link } from "react-router-dom";

const Logo = () =>{
    return(
        <div className="text-center my-3">
            <Link to="/">
            <img src="https://arrivalsolutions.in/img/logo.jpg" className="img-fluid" width={100} />
            </Link>
        </div>
    )
}

export default Logo 