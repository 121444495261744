import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Entry from './component/Entry';
import SalaryReport from './component/Stats';
import Quiz from './component/Quiz';
import Action from './component/Action';
import Certificate from './component/Certificate';
import Logo from './component/Logo';
import CertificateGenerator from './component/Certificataions';
import Landing from './component/LadingPage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Logo/>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/get-started' element={<Entry />} />
          <Route path='/our-learner' element={<SalaryReport />} />
          <Route path='/quiz' element={<Quiz />} />
          <Route path='/certification' element={<Action />} />
          <Route path='/certificate/' element={<Certificate />} />
          <Route path='/download-certificate/:userId' element={<CertificateGenerator />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
